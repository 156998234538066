import React from 'react'
import './E404.scss'
import LinkButton from '../LinkButton/LinkButton'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import e404 from '../../images/404-mascot.svg'

const E404: React.FC = () => {
  return (
    <div id="E404">
      <div className="error-m">
        <h1>Whoops!</h1>
        <div className="text">
          Looks like this page is broken. Meanwhile browse through our other
          pages from the links below.
        </div>
        <LinkButton to="/" color="#fff" background="#000">
          Go Home
        </LinkButton>
      </div>

      <div className="error-d">
        <div className="info">
          <h1>Whoops!</h1>
          <h6>Page Not Found</h6>
          <p>
            We are fixing it! Meanwhile you can browse through our most popular
            pages from the footer below.
          </p>
          <div>
            <LinkButton to="/" color="#fff" background="#000">
              Go Home
            </LinkButton>
          </div>
        </div>
        <div className="image">
          <img src={e404} alt="404 Not Found" />
        </div>
      </div>
    </div>
  )
}

export default E404

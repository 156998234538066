import React from 'react'
import E404 from '../components/E404/E404'

import Layout from '../components/layout'
import SEO from '../components/seo'

const NotFoundPage: React.FC = () => (
  <Layout>
    <SEO title="404: Not found" />
    <E404 />
  </Layout>
)

export default NotFoundPage
